var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _vm._v(" "),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-dropdown",
            { attrs: { trigger: "click" }, on: { command: _vm.commandClick } },
            [
              _c("span", { staticClass: "el-dropdown-link" }, [
                _vm._v("\n          " + _vm._s(_vm.currentLang) + " "),
                _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("el-dropdown-item", { attrs: { command: "zh" } }, [
                    _vm._v("中文"),
                  ]),
                  _vm._v(" "),
                  _c("el-dropdown-item", { attrs: { command: "en" } }, [
                    _vm._v("English"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" },
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: { src: _vm.user.avatarPath || _vm.Avatar },
                }),
                _vm._v(" "),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/user/center" } },
                    [
                      _c("el-dropdown-item", [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("ryze.fast.setting")) +
                            "\n            "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticStyle: { display: "block" },
                      on: { click: _vm.open },
                    },
                    [
                      _c("el-dropdown-item", { attrs: { divided: "" } }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("ryze.fast.logout")) +
                            "\n            "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }