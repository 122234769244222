exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "", ""]);

// exports
exports.locals = {
	"menuText": "#303133",
	"menuActiveText": "#409eff",
	"menuActiveBg": "#edf5ff",
	"subMenuActiveText": "#409eff",
	"menuBg": "#fff",
	"menuHover": "#edf5ff",
	"subMenuBg": "#fff",
	"subMenuHover": "#fff",
	"sideBarWidth": "205px"
};