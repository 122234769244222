import "core-js/modules/es6.array.of";
import request from '@/utils/request';
var menuData = {
  "code": 200,
  "msg": null,
  "data": [
  // {
  //   "name": "Pass",
  //   "path": "/event/index",
  //   "hidden": false,
  //   "redirect": "/event/index",
  //   "component": "Layout",
  //   "alwaysShow": true,
  //   "meta": {"title": "Pass", "icon": "app", "noCache": true},
  //   "children": [
  //     {
  //       "name": "Event",
  //       "path": "index",
  //       "hidden": false,
  //       "component": "event/list/index",
  //       "meta": {"title": "Event", "icon": "server", "noCache": true}
  //     },
  //     {
  //       "name": "Event Point",
  //       "path": "point",
  //       "hidden": false,
  //       "component": "eventPoint/list/index",
  //       "meta": {"title": "Event Point", "icon": "server", "noCache": true}
  //     },
  //   ]
  // },
  // {
  //   "name": "Game Data",
  //   "path": "/game/index",
  //   "hidden": false,
  //   "redirect": "/game/index",
  //   "component": "Layout",
  //   "alwaysShow": true,
  //   "meta": {"title": "Game Data", "icon": "app", "noCache": true},
  //   "children": [
  //     {
  //       "name": "Battle Statement",
  //       "path": "battle",
  //       "hidden": false,
  //       "component": "game/battle/list/index",
  //       "meta": {"title": "Battle Statement", "icon": "server", "noCache": true}
  //     },
  //     {
  //       "name": "User Statement",
  //       "path": "user",
  //       "hidden": false,
  //       "component": "game/user/index",
  //       "meta": {"title": "User Statement", "icon": "server", "noCache": true}
  //     },
  //   ]
  // },
  // {
  //   "name": "News",
  //   "path": "/news/index",
  //   "hidden": false,
  //   "redirect": "/news/index",
  //   "component": "Layout",
  //   "alwaysShow": true,
  //   "meta": {"title": "News", "icon": "app", "noCache": true},
  //   "children": [
  //     {
  //       "name": "News list",
  //       "path": "index",
  //       "hidden": false,
  //       "component": "news/list/index",
  //       "meta": {"title": "News list", "icon": "server", "noCache": true}
  //     },
  //   ]
  // },
  // {
  //   "name": "Match",
  //   "path": "/match/index",
  //   "hidden": false,
  //   "redirect": "/match/index",
  //   "component": "Layout",
  //   "alwaysShow": true,
  //   "meta": {"title": "Operate", "icon": "app", "noCache": true},
  //   "children": [
  //     {
  //       "name": "Create Match",
  //       "path": "index",
  //       "hidden": false,
  //       "component": "match/list/index",
  //       "meta": {"title": "Create Match", "icon": "server", "noCache": true}
  //     },
  //   ]
  // },
  // {
  //   "name": "Guild",
  //   "path": "/guild/index",
  //   "hidden": false,
  //   "redirect": "/guild/index",
  //   "component": "Layout",
  //   "alwaysShow": true,
  //   "meta": {"title": "Guild", "icon": "app", "noCache": true},
  //   "children": [
  //     {
  //       "name": "Guild List",
  //       "path": "index",
  //       "hidden": false,
  //       "component": "guild/list/index",
  //       "meta": {"title": "Guild List", "icon": "server", "noCache": true}
  //     },
  //   ]
  // },

  {
    "name": "Research",
    "path": "/research/index",
    "hidden": false,
    "redirect": "/research/index",
    "component": "Layout",
    "alwaysShow": true,
    "meta": {
      "title": "Research",
      "icon": "app",
      "noCache": true
    },
    "children": [{
      "name": "Research list",
      "path": "index",
      "hidden": false,
      "component": "research/list/index",
      "meta": {
        "title": "Research list",
        "icon": "server",
        "noCache": true
      }
    }]
  }, {
    "name": "Portfolio",
    "path": "/portfolio/index",
    "hidden": false,
    "redirect": "/portfolio/index",
    "component": "Layout",
    "alwaysShow": true,
    "meta": {
      "title": "Portfolio",
      "icon": "app",
      "noCache": true
    },
    "children": [{
      "name": "Portfolio list",
      "path": "index",
      "hidden": false,
      "component": "portfolio/list/index",
      "meta": {
        "title": "Portfolio list",
        "icon": "server",
        "noCache": true
      }
    }]
  }, {
    "name": "Team",
    "path": "/team/index",
    "hidden": false,
    "redirect": "/team/index",
    "component": "Layout",
    "alwaysShow": true,
    "meta": {
      "title": "Team",
      "icon": "app",
      "noCache": true
    },
    "children": [{
      "name": "Team list",
      "path": "index",
      "hidden": false,
      "component": "team/list/index",
      "meta": {
        "title": "Team list",
        "icon": "server",
        "noCache": true
      }
    }]
  }, {
    "name": "Contact",
    "path": "/contact/index",
    "hidden": false,
    "redirect": "/contact/index",
    "component": "Layout",
    "alwaysShow": true,
    "meta": {
      "title": "Contact",
      "icon": "app",
      "noCache": true
    },
    "children": [{
      "name": "Contact list",
      "path": "index",
      "hidden": false,
      "component": "contact/list/index",
      "meta": {
        "title": "Contact list",
        "icon": "server",
        "noCache": true
      }
    }]
  }]
};
export function getMenusTree(pid) {
  return request({
    url: 'menus/lazy?pid=' + pid,
    method: 'get'
  });
}
export function getMenus(params) {
  return request({
    url: 'menus',
    method: 'get',
    params: params
  });
}
export function getMenuSuperior(ids) {
  var data = Array.isArray(ids) || ids.length === 0 ? ids : Array.of(ids);
  return request({
    url: 'menus/superior',
    method: 'post',
    data: data
  });
}
export function getChild(id) {
  return request({
    url: 'menus/child?id=' + id,
    method: 'get'
  });
}
export function buildMenus() {
  // return Promise.resolve(menuData.data)
  return request({
    url: 'menus/build',
    method: 'get'
  });
}
export function add(data) {
  return request({
    url: 'menus',
    method: 'post',
    data: data
  });
}
export function del(ids) {
  return request({
    url: 'menus',
    method: 'delete',
    data: ids
  });
}
export function edit(data) {
  return request({
    url: 'menus',
    method: 'put',
    data: data
  });
}
export default {
  add: add,
  edit: edit,
  del: del,
  getMenusTree: getMenusTree,
  getMenuSuperior: getMenuSuperior,
  getMenus: getMenus,
  getChild: getChild
};

// https://gtest-admin-api.tearingspaces.com/api/admin/gameServer/gameDataProxy?path=%2Fgamedata%2Fget_battle_statement&page=1&pagesize=10&start_time=0&end_time=0
// https://gtest-admin-api.tearingspaces.com/api/admin/news?pageNo=1&pageSize=10&sort=id%2Cdesc