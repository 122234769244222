import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import store from '@/store';
export default {
  inserted: function inserted(el, binding) {
    // return true;
    var value = binding.value;
    var roles = store.getters && store.getters.roles;
    if (value && value instanceof Array) {
      if (value.length > 0) {
        var permissionRoles = value;
        var hasPermission = roles.some(function (role) {
          return permissionRoles.includes(role);
        });
        if (!hasPermission) {
          el.parentNode && el.parentNode.removeChild(el);
        }
      }
    } else {
      throw new Error("\u4F7F\u7528\u65B9\u5F0F\uFF1A v-permission=\"['admin','editor']\"");
    }
  }
};