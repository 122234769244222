'use strict';

exports.__esModule = true;
exports.default = {
  ryze: {
    cropper: {
      modalTitle: '图片裁切'
    },
    media: {
      modalTitle: '视频上传'
    },
    alert: {
      del: '删除成功',
      edit: '修改成功',
      add: '新增成功',
      copy: '已复制'
    },
    tagView: {
      refresh: '刷新',
      close: '关闭',
      close_other: '关闭其他',
      close_all: '关闭全部'
    },
    fast: {
      setting: '个人中心',
      logout: '退出登录'
    },
    myDict: {
      sex: {
        male: '男',
        female: '女'
      },
      confirm: {
        yes: '是',
        no: '否'
      },
      menu_type: {
        directory: '目录',
        menu: '菜单',
        button: '按钮'
      }
    },
    operation: {
      header: "操作",
      preview: '预览',
      cancel: '取消',
      confirm: '确认',
      search: '搜索',
      reset: '重置',
      add: '增加',
      edit: '编辑',
      del: '删除',
      export: '导出',
      selectAll: '全选',
      forceQuit: '强退'
    },
    insights: {
      modal: {
        add: '增加文化视角',
        edit: "编辑文化视角",
        editlang: "编辑多语言"
      },
      placeholder: {
        publishTime: {
          start: '起',
          end: '止'
        }
      },
      form: {
        id: '编号',
        title: '标题',
        cover: '图片',
        shownInHomepage: '首页显示',
        homePageCover: '首页封面',
        category: '类别',
        author: '发布人',
        publishTime: '发布时间',
        description: '描述',
        detail: '详情'
      },
      operation: {
        deleteTips: '友情提示，您确认删除吗?'
      },
      tips: {
        red_350: '推荐350 x 350',
        red_700: '推荐700 X 700'
      }
    },
    portfolio: {
      modal: {
        add: '增加投资组合',
        edit: "编辑投资组合"
      },
      placeholder: {
        createTime: {
          start: '起',
          end: '止'
        }
      },
      form: {
        sort: '排序',
        name: '名称',
        logo: '标志',
        category: '类别',
        website: '网址',
        createTime: '创建时间'
      },
      operation: {
        deleteTips: '友情提示，您确认删除吗?'
      }
    },
    team: {
      modal: {
        add: '增加成员',
        edit: "编辑成员"
      },
      placeholder: {
        createTime: {
          start: '起',
          end: '止'
        }
      },
      form: {
        sort: '排序',
        name: '姓名',
        pic: '头像',
        jobTitle: '职务',
        jobTitleCh: '中文职务',
        socialMedia: '社交媒体',
        x: '推特',
        linkedin: '领英',
        email: '邮箱',
        telegram: '电报',
        description: '描述',
        descriptionCh: '中文描述',
        createTime: '创建时间'
      },
      operation: {
        deleteTips: '友情提示，您确认删除吗?'
      }
    },
    contact: {
      placeholder: {
        createTime: {
          start: '起',
          end: '止'
        }
      },
      form: {
        id: '编号',
        name: '姓名',
        email: '邮箱',
        content: '内容',
        createTime: '提交时间',
        details: '详情',
        socialMedia: 'Social Media',
        subject: 'Subject',
        file: 'File'
      }
    },
    reviews: {
      modal: {
        add: '增加寄语',
        edit: "编辑寄语"
      },
      placeholder: {
        createTime: {
          start: '起',
          end: '止'
        }
      },
      form: {
        id: '编号',
        name: '姓名',
        pic: '头像',
        jobTitle: '职务',
        jobTitleCh: '中文职务',
        description: '描述',
        descriptionCh: '中文描述',
        createTime: '创建时间'
      },
      operation: {
        deleteTips: '友情提示，您确认删除吗?'
      }
    },
    subscription: {
      placeholder: {
        subscriptionTime: {
          start: '起',
          end: '止'
        }
      },
      form: {
        email: '邮箱',
        status: '状态',
        subscriptionTime: '订阅时间',
        name: 'Name',
        enquiry: "Enquiry"
      }
    },
    valueAdd: {
      modal: {
        add: '增加附加价值',
        edit: "编辑附加价值"
      },
      placeholder: {
        createTime: {
          start: '起',
          end: '止'
        }
      },
      form: {
        sort: '排序',
        title: '标题',
        cover: '图片',
        description: '描述',
        createTime: '创建时间'
      },
      operation: {
        deleteTips: '友情提示，您确认删除吗?'
      }
    },
    user: {
      modal: {
        add: '增加用户',
        edit: '编辑用户'
      },
      placeholder: {
        searchDept: "输入部门名称搜索",
        nameOrEmail: "输入名称或者邮箱搜索",
        start: "起",
        end: "止",
        status: "状态"
      },
      form: {
        username: '用户名',
        nickname: '昵称',
        sex: '性别',
        phone: '电话',
        email: '邮箱',
        department: '部门',
        status: '状态',
        createTime: '创建时间',
        role: '角色',
        position: '岗位'
      },
      operation: {
        deleteTips: '确定删除本条数据吗？'
      }
    },
    role: {
      headers: {
        list: '角色列表',
        distribution: "菜单分配",
        distributionTips: '选择指定角色分配菜单'
      },
      modal: {
        add: '新增角色',
        edit: '编辑角色'
      },
      placeholder: {
        searchByName: "输入名称搜索",
        start: "起",
        end: "止"
      },
      form: {
        name: '角色名称',
        dataAuth: '数据权限',
        roleLevel: '角色级别',
        description: '描述',
        createTime: '创建时间'
      },
      operation: {
        save: '保存'
      }
    },
    menu: {
      modal: {
        add: '增加菜单',
        edit: '编辑菜单'
      },
      placeholder: {
        searchByTitle: '模糊搜索',
        start: "起",
        end: "止",
        icon: '点击选择图标',
        iconSearch: '请输入图标名称',
        title: '英文菜单标题',
        title_zh: '中文菜单标题',
        external_link: "匹配组件内Name字段"
      },
      form: {
        menu_type: '菜单类型',
        icon: '图标',
        external_link: '外链',
        cache: '缓存',
        visible: '可见',
        title: "英文标题",
        title_zh: '中文标题',
        button_name: '按钮名称',
        permission_identification: '权限标识',
        router_path: '路由地址',
        sort: '排序',
        component_name: '组件名称',
        component_path: '组件路径',
        parent_directory: "上级目录",
        createTime: '创建时间'
      },
      operation: {
        deleteTips: '确定删除吗,如果存在下级节点则一并删除，此操作不能撤销！'
      }
    },
    dept: {
      modal: {
        add: '增加部门',
        edit: '编辑部门'
      },
      placeholder: {
        searchByName: "输入部门名称搜索",
        start: "起",
        end: "止",
        status: '状态',
        prevDept: '选择上级类目'
      },
      form: {
        name: '名称',
        sort: '排序',
        status: '状态',
        createTime: '创建时间',
        prevDept: '上级部门',
        topDept: '顶级部门'
      },
      operation: {
        deleteTips: '确定删除本条数据吗？'
      }
    },
    position: {
      modal: {
        add: '增加岗位',
        edit: '编辑岗位'
      },
      placeholder: {
        searchByName: "输入岗位名称搜索",
        start: "起",
        end: "止",
        status: '状态'
      },
      form: {
        name: '名称',
        sort: '排序',
        status: '状态',
        createTime: '创建时间'
      },
      operation: {
        deleteTips: '确定删除吗,如果存在下级节点则一并删除，此操作不能撤销！'
      }
    },
    dict: {
      headers: {
        title: '字典详情',
        description: "点击字典查看详情"
      },
      modal: {
        add: '新增字典',
        edit: '编辑字典',
        detailAdd: '新增字典详情'
      },
      placeholder: {
        searchByName: "输入名称或者描述搜索"
      },
      form: {
        name: '字典名称',
        description: '描述'
      },
      detailForm: {
        owning: '所属字典',
        label: '英文标签',
        labelZh: '中文标签',
        value: '字典值',
        sort: '排序'
      },
      operation: {
        add: '新增'
      }
    },
    online: {
      placeholder: {
        search: '全表模糊搜索'
      },
      form: {
        username: '用户名',
        nickname: '用户昵称',
        department: '部门',
        loginIp: '登录 IP',
        loginAddress: '登录地址',
        browser: '浏览器',
        loginTime: '登录时间'
      },
      operation: {
        exit: '强退'
      }
    },
    center: {
      headers: {
        info: '个人信息',
        tables: '用户资料'
      },
      info: {
        avatar: '点击上传头像',
        account: '登录账号',
        nickname: '用户昵称',
        department: '所属部门',
        mobile_phone: '手机号码',
        email: '用户邮箱',
        setting: '安全设置',
        passwordMdy: '修改密码',
        emailMdy: '修改邮箱'
      },
      form: {
        nickname: '昵称',
        mobile_phone: '手机号',
        sex: '性别'
      },
      operation: {
        save: '保存'
      },
      prompt: {
        nickname: '用户昵称不作为登录使用',
        mobile_phone: '手机号码不能重复'
      }
    },
    password: {
      modal: {
        edit: '修改密码'
      },
      form: {
        password: '旧密码',
        newPassword: '新密码',
        confirmPassword: '确认密码'
      }
    },
    email: {
      modal: {
        edit: '修改邮箱'
      },
      form: {
        email: '新邮箱',
        code: '验证码',
        password: '当前密码'
      }
    },
    login: {
      headers: {
        title: '瑞兹后台管理系统'
      },
      placeholder: {
        account: '账号',
        password: '密码',
        code: '验证码'
      },
      form: {
        remember: '记住我'
      },
      button: {
        login: '登录'
      },
      logout: {
        title: '提示',
        content: '确定注销并退出系统吗？'
      }
    }
  }
};