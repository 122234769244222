import "core-js/modules/web.dom.iterable";
import { constantRouterMap } from '@/router/routers';
import Layout from '@/layout/index';
import ParentView from '@/components/ParentView';
var permission = {
  state: {
    routers: constantRouterMap,
    addRouters: [],
    sidebarRouters: []
  },
  mutations: {
    SET_ROUTERS: function SET_ROUTERS(state, routers) {
      state.addRouters = routers;
      state.routers = constantRouterMap.concat(routers);
    },
    SET_SIDEBAR_ROUTERS: function SET_SIDEBAR_ROUTERS(state, routers) {
      state.sidebarRouters = constantRouterMap.concat(routers);
    }
  },
  actions: {
    GenerateRoutes: function GenerateRoutes(_ref, asyncRouter) {
      var commit = _ref.commit;
      commit('SET_ROUTERS', asyncRouter);
    },
    SetSidebarRouters: function SetSidebarRouters(_ref2, sidebarRouter) {
      var commit = _ref2.commit;
      commit('SET_SIDEBAR_ROUTERS', sidebarRouter);
    }
  }
};
export var filterAsyncRouter = function filterAsyncRouter(routers) {
  var lastRouter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  // 遍历后台传来的路由字符串，转换为组件对象
  return routers.filter(function (router) {
    if (type && router.children) {
      router.children = filterChildren(router.children);
    }
    if (router.component) {
      if (router.component === 'Layout') {
        // Layout组件特殊处理
        router.component = Layout;
      } else if (router.component === 'ParentView') {
        router.component = ParentView;
      } else {
        var component = router.component;
        router.component = loadView(component);
      }
    }
    if (router.children != null && router.children && router.children.length) {
      router.children = filterAsyncRouter(router.children, router, type);
    } else {
      delete router['children'];
      delete router['redirect'];
    }
    return true;
  });
};
function filterChildren(childrenMap) {
  var lastRouter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var children = [];
  childrenMap.forEach(function (el, index) {
    if (el.children && el.children.length) {
      if (el.component === 'ParentView') {
        el.children.forEach(function (c) {
          c.path = el.path + '/' + c.path;
          if (c.children && c.children.length) {
            children = children.concat(filterChildren(c.children, c));
            return;
          }
          children.push(c);
        });
        return;
      }
    }
    if (lastRouter) {
      el.path = lastRouter.path + '/' + el.path;
    }
    children = children.concat(el);
  });
  return children;
}
export var loadView = function loadView(view) {
  return function (resolve) {
    return require(["@/views/".concat(view)], resolve);
  };
};
export default permission;