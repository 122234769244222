var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.myTags, function (tag) {
        return _c(
          "el-tag",
          {
            key: tag,
            attrs: {
              type: _vm.type,
              size: "small",
              hit: "",
              effect: "dark",
              closable: "",
              "disable-transitions": false,
            },
            on: {
              close: function ($event) {
                return _vm.handKeywordTagClose(tag)
              },
            },
          },
          [_vm._v("\n    " + _vm._s(tag) + "\n  ")]
        )
      }),
      _vm._v(" "),
      _vm.inputKeywordTagVisible
        ? _c("el-input", {
            ref: "saveKeywordTagInput",
            staticClass: "input-new-tag",
            attrs: { size: "small" },
            on: { blur: _vm.handleKeywordTagInputConfirm },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleKeywordTagInputConfirm.apply(null, arguments)
              },
            },
            model: {
              value: _vm.inputKeywordTagValue,
              callback: function ($$v) {
                _vm.inputKeywordTagValue = $$v
              },
              expression: "inputKeywordTagValue",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.inputKeywordTagVisible && _vm.myTags.length < _vm.maxTagNum
        ? _c(
            "el-button",
            {
              staticClass: "button-new-tag",
              attrs: { size: "small" },
              on: { click: _vm.showKeywordTagInput },
            },
            [_vm._v("+ 添加\n  ")]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }