import Vue from 'vue';
import Router from 'vue-router';
import Layout from "../layout/index";
import Cookies from 'js-cookie';
import { dashboardText, myCenter } from '@/settings';
var language = Cookies.get('lang') || 'en';

// import routerResearch from '@/router/router-reSearch'
// import routerTeam from '@/router/router-team'
// import routerContact from '@/router/router-contact'
// import routerPortfolio from '@/router/router-portfolio'
Vue.use(Router);
export var constantRouterMap = [{
  path: '/login',
  meta: {
    title: '登录',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/login'], resolve);
  },
  hidden: true
}, {
  path: '/404',
  component: function component(resolve) {
    return require(['@/views/features/404'], resolve);
  },
  hidden: true
}, {
  path: '/401',
  component: function component(resolve) {
    return require(['@/views/features/401'], resolve);
  },
  hidden: true
}, {
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component(resolve) {
      return require(['@/views/features/redirect'], resolve);
    }
  }]
}, {
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: function component(resolve) {
      return require(['@/views/home'], resolve);
    },
    name: 'Dashboard',
    meta: {
      title: language === 'en' ? 'Dashboard' : dashboardText,
      icon: 'index',
      affix: true,
      noCache: true
    }
  }]
},
// routerResearch,
// routerTeam,
// routerContact,
// routerPortfolio,
{
  path: '/user',
  component: Layout,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'center',
    component: function component(resolve) {
      return require(['@/views/system/user/center'], resolve);
    },
    name: language === 'en' ? 'My Center' : myCenter,
    meta: {
      title: language === 'en' ? 'My Center' : myCenter
    }
  }]
}];
export default new Router({
  // mode: 'hash',
  mode: 'history',
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRouterMap
});