import request from '@/utils/request';
import requests from '@/utils/requests';
export function fileUpload(data) {
  return request({
    url: 'file/upload',
    method: 'post',
    data: data
  });
}
export function mediaAddMedia(data) {
  return requests({
    url: 'admin/media/addMedia',
    method: 'post',
    data: data
  });
}