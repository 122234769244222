// 适配 Nginx 反向代理
var baseUrl = process.env.VUE_APP_BASE_API === '/' ? '' : process.env.VUE_APP_BASE_API;
var api = {
  state: {
    // 图片上传
    fileUploadApi: baseUrl + '/file/upload',
    editorFileUploadApi: baseUrl + '/file/editor/upload',
    // 修改头像
    updateAvatarApi: baseUrl + '/users/updateAvatar',
    // 文件上传
    // baseUrl，
    baseApi: baseUrl
  }
};
export default api;