import "E:\\project\\ryze-admin-website\\node_modules\\core-js\\modules\\es6.array.iterator.js";
import "E:\\project\\ryze-admin-website\\node_modules\\core-js\\modules\\es6.promise.js";
import "E:\\project\\ryze-admin-website\\node_modules\\core-js\\modules\\es6.object.assign.js";
import "E:\\project\\ryze-admin-website\\node_modules\\core-js\\modules\\es7.promise.finally.js";
import Vue from 'vue';
import { setLang } from '@/utils/lang';
import Cookies from 'js-cookie';
import '@/mixins/formMixin';
import 'normalize.css/normalize.css';
import Element from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';

//
import mavonEditor from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';

// 数据字典
import dict from "./components/Dict";

// 权限指令
import checkPer from '@/utils/permission';
import permission from "./components/Permission";
import "./assets/styles/element-variables.scss";
// global css
import "./assets/styles/index.scss";

// 代码高亮
import VueHighlightJS from 'vue-highlightjs';
import 'highlight.js/styles/atom-one-dark.css';
// 图片裁切
import VueCropper from 'vue-cropper';
import App from "./App";
import store from "./store";
import router from "./router/routers";
import "./assets/icons"; // icon
import "./router/index"; // permission control
import 'echarts-gl';
Vue.use(checkPer);
Vue.use(VueHighlightJS);
Vue.use(mavonEditor);
Vue.use(permission);
Vue.use(dict);
// Vue.use(Element, {
// locale,
// size: Cookies.get('size') || 'small' // set element-ui default size
// })
Vue.use(VueCropper);
Vue.config.productionTip = false;
var _setLang = setLang(Vue, Element),
  i18n = _setLang.i18n;
// 全局组件
import ryzeCropper from '@/components/ryze-cropper/ryze-cropper.vue';
Vue.component('ryzeCropper', ryzeCropper);
new Vue({
  el: '#app',
  i18n: i18n,
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});