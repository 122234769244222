'use strict';

exports.__esModule = true;
exports.default = {
  ryze: {
    cropper: {
      modalTitle: 'Picture Cropping'
    },
    media: {
      modalTitle: 'Upload video'
    },
    alert: {
      del: 'Successfully deleted',
      edit: 'Modified successfully',
      add: 'Created successfully',
      fail: 'Operation Failed',
      copy: 'Copied'
    },
    tagView: {
      refresh: 'Refresh',
      close: 'Close',
      close_other: 'Close other',
      close_all: 'Close all'
    },
    fast: {
      setting: 'Account Settings',
      logout: 'Log out'
    },
    myDict: {
      sex: {
        male: 'Male',
        female: 'Female'
      },
      confirm: {
        yes: 'Yes',
        no: 'No'
      },
      menu_type: {
        directory: 'Directory',
        menu: 'Menu',
        button: 'Button'
      }
    },
    operation: {
      header: "Operation",
      preview: 'Preview',
      cancel: 'Cancel',
      confirm: 'Confirm',
      search: 'Search',
      reset: 'Reset',
      add: 'Add',
      edit: 'Edit',
      del: 'Delete',
      export: 'Export',
      selectAll: 'Select All',
      forceQuit: 'Force to Quit'
    },
    insights: {
      modal: {
        add: 'Add Insights',
        edit: "Edit Insights",
        editlang: "Edit Language"
      },
      placeholder: {
        publishTime: {
          start: 'Start',
          end: 'End'
        }
      },
      form: {
        id: 'ID',
        title: 'Title',
        cover: 'Cover',
        shownInHomepage: 'Shown In Homepage',
        homePageCover: 'Home Page Cover',
        category: 'Category',
        author: 'Author',
        publishTime: 'Publish time',
        description: 'Description',
        detail: 'Detail'
      },
      operation: {
        deleteTips: 'Once you delete this insights, it will be disappear immediately'
      },
      tips: {
        red_350: '350 x 350 recommended',
        red_700: '700 X 700 recommended'
      }
    },
    portfolio: {
      modal: {
        add: 'Add Portfolio',
        edit: "Edit Portfolio"
      },
      placeholder: {
        createTime: {
          start: 'Start',
          end: 'End'
        }
      },
      form: {
        sort: 'Sort',
        name: 'Name',
        logo: 'Logo',
        category: 'Category',
        website: 'Website',
        createTime: 'Create Time'
      },
      operation: {
        deleteTips: 'Once you delete this portfolio, it will be disappear immediately'
      }
    },
    team: {
      modal: {
        add: 'Add Team',
        edit: "Edit Team"
      },
      placeholder: {
        createTime: {
          start: 'Start',
          end: 'End'
        }
      },
      form: {
        sort: 'Sort',
        name: 'Name',
        pic: 'Pic',
        jobTitle: 'Job title',
        jobTitleCh: 'Job titleCh',
        socialMedia: 'Social Media',
        x: 'X',
        linkedin: 'Linkedin',
        email: 'E-mail',
        telegram: 'Telegram',
        description: 'Description',
        descriptionCh: 'DescriptionCh',
        createTime: 'Create time'
      },
      operation: {
        deleteTips: 'Once you delete this team, it will be disappear immediately'
      }
    },
    contact: {
      placeholder: {
        createTime: {
          start: 'Start',
          end: 'End'
        }
      },
      form: {
        id: 'ID',
        name: 'Name',
        email: 'E-mail',
        content: 'Content',
        createTime: 'create Time',
        details: 'Details',
        socialMedia: 'Social Media',
        subject: 'Subject',
        file: 'File'
      }
    },
    reviews: {
      modal: {
        add: 'Add Testimonials',
        edit: "Edit Testimonials"
      },
      placeholder: {
        createTime: {
          start: 'Start',
          end: 'End'
        }
      },
      form: {
        id: 'ID',
        name: 'Name',
        pic: 'Pic',
        jobTitle: 'Job title',
        jobTitleCh: 'Job titleCh',
        description: 'Description',
        descriptionCh: 'DescriptionCh',
        createTime: 'Create Time'
      },
      operation: {
        deleteTips: 'Once you delete this Testimonials, it will be disappear immediately'
      }
    },
    subscription: {
      placeholder: {
        subscriptionTime: {
          start: 'Start',
          end: 'End'
        }
      },
      form: {
        email: 'E-mail',
        status: 'Status',
        subscriptionTime: 'Subscription time',
        name: 'Name',
        enquiry: "Enquiry"
      }
    },
    valueAdd: {
      modal: {
        add: 'Add Value Add',
        edit: "Edit Value Add"
      },
      placeholder: {
        createTime: {
          start: 'Start',
          end: 'End'
        }
      },
      form: {
        sort: 'Sort',
        title: 'Title',
        cover: 'Cover',
        description: 'Description',
        createTime: 'Create Time'
      },
      operation: {
        deleteTips: 'Once you delete this Value add, it will be disappear immediately'
      }
    },
    user: {
      modal: {
        add: 'Add User',
        edit: 'Edit User'
      },
      placeholder: {
        searchDept: "Search by department",
        nameOrEmail: "Search by name or email",
        start: "Start",
        end: "End",
        status: "Status"
      },
      form: {
        username: 'Username',
        nickname: 'Nickname',
        sex: 'Gender',
        phone: 'Phone',
        email: 'E-mail',
        department: 'Dept',
        status: 'Status',
        createTime: 'Create Time',
        role: 'Role',
        position: 'Position'
      },
      operation: {
        deleteTips: 'Are you sure to delete this user?'
      }
    },
    role: {
      headers: {
        list: 'Role list',
        distribution: "Menu distribution",
        distributionTips: 'Select the Specify Role Assignment menu'
      },
      modal: {
        add: 'Add Role',
        edit: 'Edit Role'
      },
      placeholder: {
        searchByName: "Search by name",
        start: "Start",
        end: "End"
      },
      form: {
        name: 'Role name',
        dataAuth: 'Data permission',
        roleLevel: 'Role level',
        description: 'Description',
        createTime: 'Create time'
      },
      operation: {
        save: 'Save'
      }
    },
    menu: {
      modal: {
        add: 'Add Menu',
        edit: 'Edit Menu'
      },
      placeholder: {
        searchByTitle: 'Fuzzy search by title',
        start: "Start",
        end: "End",
        icon: 'Click Select icon',
        iconSearch: 'Please enter an icon name',
        title: 'Menu title by English',
        title_zh: 'Menu title by Chinese',
        external_link: "Matches component's Name field"
      },
      form: {
        menu_type: 'Menu type',
        icon: 'Icon',
        external_link: 'External link',
        cache: 'Cache',
        visible: 'Visible',
        title: "Title",
        title_zh: 'Title(CN)',
        button_name: 'Button Name',
        permission_identification: 'Identification',
        router_path: 'Router path',
        sort: 'Sort',
        component_name: 'Component name',
        component_path: 'Component path',
        parent_directory: "Parent directory",
        createTime: 'Create Time'
      },
      operation: {
        deleteTips: 'This action will delete the current node and the child node, this action can\'t be undone!'
      }
    },
    dept: {
      modal: {
        add: 'Add Department',
        edit: 'Edit Department'
      },
      placeholder: {
        searchByName: "Search by Dept.",
        start: "Start",
        end: "End",
        status: 'Status',
        prevDept: 'Select the parent category'
      },
      form: {
        name: 'Name',
        sort: 'Sort',
        status: 'Status',
        createTime: 'CreateTime',
        prevDept: 'Prev Dept.',
        topDept: 'Top Dept.'
      },
      operation: {
        deleteTips: 'This action will delete the current node and the child node, this action can\'t be undone!'
      }
    },
    position: {
      modal: {
        add: 'Add Position',
        edit: 'Edit Position'
      },
      placeholder: {
        searchByName: "Search by position",
        start: "Start",
        end: "End",
        status: 'Status'
      },
      form: {
        name: 'Name',
        sort: 'Sort',
        status: 'Status',
        createTime: 'CreateTime'
      }
    },
    dict: {
      headers: {
        title: 'Dictionary Detail',
        description: "Click on the dictionary for details"
      },
      modal: {
        add: 'Add Dictionary',
        edit: 'Edit Dictionary',
        detailAdd: 'Add dictionary Details'
      },
      placeholder: {
        searchByName: "Search by name or description"
      },
      form: {
        name: 'Name',
        description: 'Description'
      },
      detailForm: {
        owning: 'Owning dictionary',
        label: 'English Label',
        labelZh: 'Chinese Label ',
        value: 'Value',
        sort: 'Sort'
      },
      operation: {
        add: 'Add'
      }
    },
    online: {
      placeholder: {
        search: 'Full table fuzzy search'
      },
      form: {
        username: 'Username',
        nickname: 'Nickname',
        department: 'Dept',
        loginIp: 'IP',
        loginAddress: 'Login address',
        browser: 'Browser',
        loginTime: 'Login time'
      },
      operation: {
        exit: 'Quit'
      }
    },
    center: {
      headers: {
        info: 'Personal information',
        tables: 'User tables'
      },
      info: {
        avatar: 'Click to upload avatar',
        account: 'Account',
        nickname: 'Nickname',
        department: 'Department',
        mobile_phone: 'Mobile Phone',
        email: 'E-mail',
        setting: 'Setting',
        passwordMdy: 'Password',
        emailMdy: 'E-mail'
      },
      form: {
        nickname: 'Nickname',
        mobile_phone: 'Mobile Phone',
        sex: 'Gender'
      },
      operation: {
        save: 'Save'
      },
      prompt: {
        nickname: 'Nickname is not used for login',
        mobile_phone: 'The mobile phone number must be unique'
      }
    },
    password: {
      modal: {
        edit: 'Change Password'
      },
      form: {
        password: 'Password',
        newPassword: 'New password',
        confirmPassword: 'Confirm password'
      }
    },
    email: {
      modal: {
        edit: 'Change E-mail'
      },
      form: {
        email: 'New E-mail',
        code: 'Verification code',
        password: 'Current password'
      }
    },
    login: {
      headers: {
        title: 'Ryze Labs Management System'
      },
      placeholder: {
        account: 'Account',
        password: 'Password',
        code: 'Verification code'
      },
      form: {
        remember: 'Remember'
      },
      button: {
        login: 'Login'
      },
      logout: {
        title: 'Tip',
        content: 'Are you sure to log out and exit the system？'
      }
    }
  }
};