import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TagsForm',
  props: {
    tags: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    maxTagNum: {
      type: Number,
      required: false,
      default: 5
    },
    maxWordNum: {
      type: Number,
      required: false,
      default: 10
    },
    type: {
      type: String,
      required: false,
      default: 'success'
    }
  },
  data: function data() {
    return {
      inputKeywordTagVisible: false,
      inputKeywordTagValue: '',
      myTags: this.tags || []
    };
  },
  watch: {
    deep: true,
    immediate: true,
    tags: function tags(val) {
      if (!val || val.length < 1) {
        this.myTags = [];
      } else {
        this.myTags = val;
      }
    }
  },
  methods: {
    handKeywordTagClose: function handKeywordTagClose(tag) {
      this.myTags.splice(this.myTags.indexOf(tag), 1);
      this.$emit('changed', this.myTags);
    },
    showKeywordTagInput: function showKeywordTagInput() {
      var _this = this;
      this.inputKeywordTagVisible = true;
      this.$nextTick(function (_) {
        _this.$refs.saveKeywordTagInput.$refs.input.focus();
      });
    },
    handleKeywordTagInputConfirm: function handleKeywordTagInputConfirm() {
      var inputValue = this.inputKeywordTagValue;
      if (inputValue.length > this.maxWordNum) {
        this.$notify({
          title: "\u6807\u7B7E\u4E0D\u80FD\u8D85\u8FC7".concat(this.maxWordNum, "\u4E2A\u5B57"),
          type: 'warning',
          duration: 1500
        });
        return;
      }
      if (inputValue) {
        this.myTags.push(inputValue);
      }
      this.inputKeywordTagVisible = false;
      this.inputKeywordTagValue = '';
      this.$emit('changed', this.myTags);
    }
  }
};