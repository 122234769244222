import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "E:/project/ryze-admin-website/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import VueI18n from 'vue-i18n';
import enLocale from 'element-ui/lib/locale/lang/en';
import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
import languages_en from '@/assets/languages/en';
import languages_zh from '@/assets/languages/zh';
import Cookies from "js-cookie";
var messages = {
  en: _objectSpread(_objectSpread({
    message: 'hello'
  }, languages_en), enLocale),
  zh: _objectSpread(_objectSpread({
    message: '你好'
  }, languages_zh), zhLocale)
};
export var setLang = function setLang(Vue, Element) {
  Vue.use(VueI18n);
  Vue.use(Element, {
    locale: enLocale,
    i18n: function i18n(key, value) {
      return _i18n.t(key, value);
    },
    size: Cookies.get('size') || 'small'
  });
  // Create VueI18n instance with options
  var _i18n = new VueI18n({
    // locale: 'en', // set locale
    locale: Cookies.get('lang') || 'en',
    messages: messages // set locale messages
  });

  return {
    i18n: _i18n
  };
};