var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-containers" },
    [
      _c("div", { staticClass: "uploader" }, [
        _c("div", { staticClass: "uploader__add" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.loadingCopper,
                  expression: "!loadingCopper",
                },
              ],
              staticClass: "slot",
            },
            [_vm._t("default")],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loadingCopper,
                  expression: "loadingCopper",
                },
              ],
              staticClass: "loadingCopper",
            },
            [_c("i", { staticClass: "el-icon-loading" })]
          ),
        ]),
        _vm._v(" "),
        _c("input", {
          ref: "inputFile",
          attrs: {
            type: "file",
            id: "uploads",
            accept: "image/png, image/jpeg, image/gif, image/jpg",
          },
          on: {
            change: function ($event) {
              return _vm.uploadImg($event, 1)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            top: "20",
            visible: _vm.dialog,
            "close-on-click-modal": false,
            "before-close": _vm.cancel,
            title: _vm.$t("ryze.cropper.modalTitle"),
            "append-to-body": "",
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialog = $event
            },
          },
        },
        [
          _c("div", { attrs: { id: "apps__body" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.model,
                    expression: "model",
                  },
                ],
                staticClass: "model",
                on: {
                  click: function ($event) {
                    _vm.model = false
                  },
                },
              },
              [
                _c("div", { staticClass: "model-show" }, [
                  _c("img", { attrs: { src: _vm.modelSrc, alt: "" } }),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "cut" },
              [
                _c("vue-cropper", {
                  ref: "cropper",
                  attrs: {
                    img: _vm.option.img,
                    "output-size": _vm.option.size,
                    "output-type": _vm.option.outputType,
                    info: true,
                    full: _vm.option.full,
                    fixed: _vm.fixed,
                    "fixed-number": _vm.fixedNumber,
                    "can-move": _vm.option.canMove,
                    "can-move-box": _vm.option.canMoveBox,
                    "fixed-box": _vm.option.fixedBox,
                    original: _vm.option.original,
                    "auto-crop": _vm.option.autoCrop,
                    "auto-crop-width": _vm.option.autoCropWidth,
                    "auto-crop-height": _vm.option.autoCropHeight,
                    "center-box": _vm.option.centerBox,
                    high: _vm.option.high,
                    mode: "contain",
                    "max-img-size": _vm.option.max,
                    "limit-min-size": _vm.limitMinSize,
                  },
                  on: {
                    "real-time": _vm.realTime,
                    "img-load": _vm.imgLoad,
                    "crop-moving": _vm.cropMoving,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "show-preview",
                style: {
                  width: _vm.previews.w + "px",
                  height: _vm.previews.h + "px",
                  overflow: "hidden",
                  margin: "5px",
                },
              },
              [
                _c("div", { style: _vm.previews.div }, [
                  _c("img", {
                    style: _vm.previews.img,
                    attrs: { src: _vm.previews.url },
                  }),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "test-button" },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.changeScale(1)
                      },
                    },
                  },
                  [_c("i", { staticClass: "el-icon-zoom-in" })]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.changeScale(-1)
                      },
                    },
                  },
                  [_c("i", { staticClass: "el-icon-zoom-out" })]
                ),
                _vm._v(" "),
                _c("el-button", { on: { click: _vm.rotateLeft } }, [
                  _c("i", { staticClass: "el-icon-refresh-left" }),
                ]),
                _vm._v(" "),
                _c("el-button", { on: { click: _vm.rotateRight } }, [
                  _c("i", {
                    staticClass: "el-icon-refresh-left",
                    staticStyle: { transform: "rotateY(180deg)" },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.finish("base64")
                      },
                    },
                  },
                  [_c("i", { staticClass: "el-icon-picture-outline-round" })]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "text" }, on: { click: _vm.cancel } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("ryze.operation.cancel")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loading, type: "primary" },
                  on: { click: _vm.doSubmit },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("ryze.operation.confirm")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }